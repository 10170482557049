type VideoDetailPropsType = {
  videoId: string | null;
};

const VideoDetail: React.FC<VideoDetailPropsType> = (props) => {
  const { videoId } = props;

  return (
    <div>
      <div>VideoDetail</div>
      <div>{videoId}</div>
    </div>
  );
};

export default VideoDetail;
