import { useLocation } from 'react-router-dom';
import { Layout } from '../components/layout';
import VideoDetail from '../components/template/videoDetail';

const DetailPage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const videoId = query.get('video_id');

  return (
    <Layout title="動画詳細">
      <VideoDetail videoId={videoId}></VideoDetail>
    </Layout>
  );
};

export default DetailPage;
