import { useState } from 'react';

export const useSessionStorage = (key: String, initVal: {}) => {
  const ky = `video-transporter:${key}`;

  const value = () => {
    try {
      const item = window.sessionStorage.getItem(ky);
      return item ? JSON.parse(item) : initVal;
    } catch (error) {
      console.error(error);
      return initVal;
    }
  };

  const setValue = (val: {}) => {
    try {
      setSavedValue(val);
      window.sessionStorage.setItem(ky, JSON.stringify(val));
    } catch (error) {
      console.error(error);
    }
  };

  const [savedValue, setSavedValue] = useState(value);

  return [savedValue, setValue];
};
