import { createContext, ReactNode, useState } from 'react';

type SnackbarInfo = {
  isOpen: boolean;
  level: string;
  message: string;
};

type SnackbarInfoContextType = {
  snackbarInfo: SnackbarInfo;
  setSnackbarInfo: (snackbarInfo: SnackbarInfo) => void;
};

export const SnackbarContext = createContext<SnackbarInfoContextType>({
  snackbarInfo: { isOpen: false, level: 'info', message: 'sample message' },
  setSnackbarInfo: (snackbarInfo: SnackbarInfo) => {},
});

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // init
  const [snackbarInfo, setSnackbarInfo] = useState<SnackbarInfo>({
    isOpen: false,
    level: 'info',
    message: 'sample message',
  });

  return <SnackbarContext.Provider value={{ snackbarInfo, setSnackbarInfo }}>{children}</SnackbarContext.Provider>;
};
