import '../styles/pages/login.css';

import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { execRequest } from '../service/request';
import { LoginResponse } from '../types/api_type/login';
import { API } from '../const/api';

function LoginPage(): JSX.Element {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  // const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [, setAuthSession] = useAuth();

  const changeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const login = async () => {
    setErrorMessage('');

    if (!username) {
      setErrorMessage('ユーザー名を入力してください');
      return;
    }

    if (!password) {
      setErrorMessage('パスワードを入力してください');
      return;
    }

    if (password.indexOf(' ') !== -1) {
      setErrorMessage('パスワードに空文字を含めないでください');
      return;
    }

    const resp = await execRequest<LoginResponse>(API.LOGIN, {
      method: 'POST',
      body: {
        email: username, // TODO request change
        password: password,
      },
    });

    if (!resp.ok) {
      setErrorMessage('ログインに失敗しました');
      return;
    }

    setAuthSession({ token: resp.body?.token, username: username });
    navigate('/');
  };

  return (
    <section className="login-section">
      <div className="col-12 col-md-8 col-lg-6 col-xl-5">
        <div className="card shadow-2-strong login-card">
          <div className="card-body p-5 text-center">
            <>
              <h3 className="mb-5">Video Transporter</h3>

              {errorMessage && (
                <div className="alert alert-danger">
                  <div>{errorMessage}</div>
                </div>
              )}

              <div className="form-outline mb-4">
                <input
                  type="text"
                  name=""
                  id="username"
                  className="form-control form-control-lg"
                  placeholder="UserName"
                  onChange={changeUsername}
                />
              </div>
              <div className="form-outline mb-4">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  placeholder="Password"
                  onChange={changePassword}
                />
              </div>

              <button className="btn btn-primary btn-block" type="submit" onClick={login}>
                Login
              </button>
            </>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
