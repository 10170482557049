import { AiOutlineVideoCamera } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { PAGE_PATH } from '../const/pagePath';
import { useAuth } from '../hooks/useAuth';
import '../styles/header.css';

type HeaderPropsType = {
  header: string | JSX.Element;
};

export const Header: React.FC<HeaderPropsType> = (props) => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  const moveTop = () => {
    navigate(PAGE_PATH.TOP_PAGE);
  };

  const logout = () => {
    setAuth({});
    navigate(PAGE_PATH.LOGIN_PAGE);
  };

  return (
    <div className="bg-primary header">
      <div className="header-content">
        <div className="header-icon-wrapper">
          <AiOutlineVideoCamera className="header-icon" />
        </div>
        <div className="header-title" onClick={moveTop}>
          {props.header}
        </div>
      </div>
      <div className="header-content">
        <div className="m-2 header-account">{auth.username}</div>
        <button className="btn btn-outline-light btn-sm px-4 m-2" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  );
};
