import { useState } from 'react';
import { useSessionStorage } from './useSessionStorage';

const KEY = 'AUTH';

export type AuthType = {
  token?: string;
  username?: string;
};

export const useAuth = (): [AuthType, (val: AuthType) => void] => {
  const initValue: AuthType = {};

  const [savedValue, setValue] = useSessionStorage(KEY, initValue);

  const value: AuthType = {
    token: savedValue.token,
    username: savedValue.username,
  };

  const setAuth = (val: AuthType) => {
    setValue(val);
    setAuthState(val);
  };

  const [auth, setAuthState] = useState(value);
  return [auth, setAuth];
};
