import '../styles/pages/signup.css';

import { auth } from '../service/firebase';
import { sendEmailVerification, createUserWithEmailAndPassword } from 'firebase/auth';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  const [email, setEamil] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEamil(e.target.value);
  };

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const changeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const pushLoginPage = () => {
    navigate('/login');
  };

  const signup = () => {
    console.log('email is ', email);
    console.log('password is ', password);
    console.log('confirm pass is ', confirmPassword);

    console.log(password === confirmPassword);

    if (!email || !password) {
      setErrorMessage('メールアドレスまたはパスワードを入力してください');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('パスワードが確認用パスワードと異なります');
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // varification email
        sendEmailVerification(userCredential.user)
          .then(() => {
            setErrorMessage('');
            setInfoMessage(
              '検証メールを送信しました、メール内のリンクをクリック後、ログインページでログインしてください'
            );
            auth.signOut();
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage('検証メールの送信に失敗しました');
          });
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage('ユーザーの作成に失敗しました');
      });
  };

  return (
    <div className="signup-section">
      <div className="col-12 col-md08 col-lg-6 col-xl-5">
        <div className="card shadow-2-strong signup-card">
          <div className="card-body p-5 text-center">
            <h3 className="">Video Transporter</h3>
            <h4 className="mb-5">Sign up</h4>

            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            {infoMessage && <div className="alert alert-primary">{infoMessage}</div>}

            <div className="front-outline mb-4">
              <input
                type="email"
                id="email"
                className="form-control form-control-lg"
                placeholder="Email"
                onChange={changeEmail}
              />
            </div>
            <div className="front-outline mb-4">
              <input
                type="password"
                className="form-control form-control-lg"
                placeholder="Password"
                onChange={changePassword}
              />
            </div>
            <div className="front-outline mb-4">
              <input
                type="password"
                className="form-control form-control-lg"
                placeholder="確認用Password"
                onChange={changeConfirmPassword}
              />
            </div>

            <div className="signup-button-container">
              <button className="btn btn-primary btn-block" onClick={signup}>
                SignUp
              </button>

              <button className="btn btn-secondary btn-block" onClick={pushLoginPage}>
                ログインページへ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
