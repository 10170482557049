import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TopPage from './pages/index';
import LoginPage from './pages/login';
import SignUp from './pages/signup';
import DetailPage from './pages/detail';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { SimpleSnackbar } from './components/simpleSnackbar';

function App() {
  return (
    <SnackbarProvider>
      <SimpleSnackbar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TopPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/detail" element={<DetailPage />}></Route>
        </Routes>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
