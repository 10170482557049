import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header } from './header';
import { Footer } from './footer';

type LayoutPropsType = {
  title: string;
  children: JSX.Element | string;
};

export const Layout: React.FC<LayoutPropsType> = (props) => {
  const { title, children } = props;

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Video Transporter | {title}</title>
        </Helmet>
      </HelmetProvider>
      <Header header="Video Transporter" />
      <div className="container">
        <h3 className="my-3 text-primary text-center">{title}</h3>
        {children}
      </div>
      <Footer footer={'copyright yun_sunjin'} />
    </div>
  );
};
