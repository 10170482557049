import { Video } from '../../types/api_type/video';
import { DetailModalProps } from '../../types/modalProps';
import { useModal } from 'react-hooks-use-modal';
import { useAuth } from '../../hooks/useAuth';
import { useModalEffect } from '../../hooks/useModalEffect';
import '../../styles/template/modal.css';
import { execRequest } from '../../service/request';
import { API } from '../../const/api';
import { useSimpleSnackbar } from '../../hooks/useSimpleSnackbar';
import { SignedUrl } from '../../types/api_type/signedUrl';
import { useEffect, useState } from 'react';
import { getExtFromFilePath } from '../../service/file';

export const DownloadVideoModal: React.FC<DetailModalProps<Video>> = (props) => {
  const { isOpenModal, setIsOpenModal, target, mutate } = props;
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
  });
  const [auth] = useAuth();
  const pushSnackbar = useSimpleSnackbar();
  const [downloadURL, setDownloadURL] = useState('');
  const [downloadFileName, setDownloadFileName] = useState('');

  useModalEffect(isOpenModal, setIsOpenModal, open, close, isOpen);

  useEffect(() => {
    if (isOpen) {
      const setDownloadUrlAndFileName = async () => {
        const resp = await execRequest<SignedUrl>(API.VIDEO_DOWNLOAD_URL, {
          token: auth.token,
          email: auth.username,
          method: 'GET',
          query: {
            video_id: target.video_id,
          },
        });

        if (!resp.ok || !resp.body || !resp.body.video_url) {
          pushSnackbar('error', '動画のダウンロードパスの取得に失敗しました');
          return;
        }

        const ext = getExtFromFilePath(target.video_path);
        const filename = `${target.name}.${ext}`;

        setDownloadURL(resp.body.video_url);
        setDownloadFileName(filename);
      };

      const f = async () => {
        await setDownloadUrlAndFileName();
      };
      f();
    }
  }, [auth.username, auth.token, isOpen, pushSnackbar, target.name, target.video_id, target.video_path]);

  const countUpDownloadCountAndClose = async () => {
    if (mutate) {
      mutate();
    }

    pushSnackbar('success', 'ダウンロードしています');
    close();
  };

  return (
    <Modal>
      <div className="modal-wrapper">
        <h4 className="text-primary">動画のダウンロード</h4>
        <h5>{target.name}をダウンロードしますか?</h5>

        <div className="d-flex justify-content-around w-100 m-4">
          <button className="btn btn-secondary" onClick={close}>
            キャンセル
          </button>
          <button className={`btn btn-primary`} onClick={countUpDownloadCountAndClose}>
            <a href={downloadURL} download={downloadFileName} className="text-decoration-none text-white">
              ダウンロード
            </a>
          </button>
        </div>
      </div>
    </Modal>
  );
};
