import React, { useEffect, useState } from 'react';
import { Layout } from '../components/layout';
import { DownloadVideoModal } from '../components/template/downloadVideoModal';
import { API } from '../const/api';
import { useCreateFetch, useFetch } from '../hooks/useFetch';
import { useSimpleSnackbar } from '../hooks/useSimpleSnackbar';
import { parse, stringify } from '../service/jsonutil';
import { Video, VideoListResponse } from '../types/api_type/video';

const defaultVideo: Video = {
  video_id: '',
  name: '',
  remaining_download_count: 0,
  thumb_url: '',
  video_path: '',
};

function TopPage(): JSX.Element {
  const api = API.VIDEO_LIST;
  const fetcher = useCreateFetch<VideoListResponse>(api);
  const { data, mutate } = useFetch<VideoListResponse>([api], fetcher);
  const [videoList, setVideoList] = useState<JSX.Element[]>([]);
  const [selectVideo, setSelectVideo] = useState(defaultVideo);
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);
  const pushSnackbar = useSimpleSnackbar();

  const clickVideo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { target } = e.currentTarget.dataset;
    if (!target) {
      pushSnackbar('error', 'ダウンロードに失敗しました');
      return;
    }

    const video = parse<Video>(target);
    setSelectVideo(video);
    setIsOpenDownloadModal(true);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    let list: JSX.Element[] = [];
    if (data.video_list) {
      let cnt = 0;
      for (let video of data.video_list) {
        cnt++;

        let buttonStyle = 'btn btn-primary';
        if (video.remaining_download_count === 1) {
          buttonStyle = 'btn btn-danger';
        } else if (video.remaining_download_count <= 0) {
          buttonStyle = 'btn btn-secondary disabled';
        }

        list.push(
          <tr key={cnt}>
            <th scope="row">{cnt}</th>
            <td>{video.name}</td>
            {/* <td>残り{video.remaining_download_count}回</td> */}
            <td>
              <button className={buttonStyle} data-target={stringify(video)} onClick={clickVideo}>
                ダウンロード
              </button>
            </td>
          </tr>
        );
      }
      setVideoList(list);
    }
  }, [data]);

  return (
    <Layout title="映像ダウンロード">
      <div>
        <DownloadVideoModal
          isOpenModal={isOpenDownloadModal}
          setIsOpenModal={setIsOpenDownloadModal}
          mutate={mutate}
          target={selectVideo}
        />

        <div className="alert alert-primary">現在ダウンロードできる動画たちです</div>
        <div className="card">
          <div className="card-body">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">タイトル</th>
                    {/* <th scope='col'>回数</th> */}
                    <th scope="col">Download</th>
                  </tr>
                </thead>
                <tbody>{videoList}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TopPage;
